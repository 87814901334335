import _ from 'lodash'

export default {
  isHargaUnitError () {
    return this.errorTextHargaUnit != null
  },

  isTerminDpError () {
    return this.errorTextTerminDP != null
  },

  isDiskonError () {
    return this.errorTextDiskon != null
  },

  isPpnError () {
    return this.errorTextPpn != null
  },

  isDpError () {
    return this.errorTextDP != null
  },

  isTerminAngsuranError () {
    return this.errorTextTerminAngsuran != null
  },

  errorTextHargaUnit () {
    if (this.totalHargaUnit < this.defaultHargaUnit.harga_min) {
      return `Harga unit tidak boleh kurang dari harga minimum ${this.idr(this.defaultHargaUnit.harga_min)}.`
    }
    if (this.totalHargaUnit > this.defaultHargaUnit.harga_max) {
      return `Harga unit tidak boleh lebih dari harga maksimum ${this.idr(this.defaultHargaUnit.harga_max)}.`
    }
    return null
  },

  errorTextTerminDP () {
    const maxTerminDp = this.$systemParam('max_termin_dp')
    if (this.data.termin_dp > maxTerminDp) {
      return `Termin DP maksimal ${maxTerminDp}x.`
    }
    if (this.totalSisaUangMuka > 0 && (this.data.termin_dp || 0) < 1) {
      return 'Termin DP minimal 1x saat sisa DP lebih dari 0.'
    }
    if (this.totalSisaUangMuka <= 0 && (this.data.termin_dp || 0) > 0) {
      return 'Termin DP tidak perlu diisi jika sisa DP adalah 0.'
    }
    return null
  },

  errorTextDP () {
    if (this.data.dp_jml < 0) {
      return 'Tidak boleh minus.'
    }
    return null
  },

  errorTextDiskon () {
    if (this.data.diskon_jml < 0) {
      return 'Tidak boleh minus.'
    }
    return null
  },

  errorTextPpn () {
    if (this.data.ppn_jml < 0) {
      return 'Tidak boleh minus.'
    }
    return null
  },

  errorTextTerminAngsuran () {
    const maxTerminAngsuran = this.$systemParam('max_termin_angsuran')
    if (this.data.termin_angsuran > maxTerminAngsuran) {
      return `Termin angsuran maksimal ${maxTerminAngsuran}x.`
    }
    if (this.totalSisaAngsuran > 0 && (this.data.termin_angsuran || 0) < 1) {
      return 'Termin angsuran minimal 1x saat sisa angsuran lebih dari 0.'
    }
    if (this.totalSisaAngsuran <= 0 && (this.data.termin_angsuran || 0) > 0) {
      return 'Termin angsuran tidak perlu diisi jika sisa angsuran adalah 0.'
    }
    return null
  },

  isRefreshTerminNeeded () {
    if (this.data.angsurans.length < 1) return false

    let isRefreshTerminNeeded = false
    const angsurans = this.data.angsurans
    const listTerminBiayaUnit = _.filter(angsurans, item => item.group === 'BIAYA UNIT')
    const listTerminBiayaTambahan = _.filter(angsurans, item => item.group === 'BIAYA TAMBAHAN')
    const listTerminUangMuka = _.filter(angsurans, item => item.group === 'UANG MUKA')
    const listTerminAngsuran = _.filter(angsurans, item => item.group === 'ANGSURAN')

    // validate termin biaya unit length
    const terminBiayaUnitLength = this.listBiayaUnitAsTermin.length
    if (terminBiayaUnitLength !== listTerminBiayaUnit.length) {
      isRefreshTerminNeeded = true
    }

    // validate termin biaya tambahan length
    const terminBiayaTambahanLength = this.listBiayaTambahanAsTermin.length
    if (terminBiayaTambahanLength !== listTerminBiayaTambahan.length) {
      isRefreshTerminNeeded = true
    }

    // validate termin uang muka length
    const terminUangMukaLength = parseInt(this.data.termin_dp || 0)
    if (terminUangMukaLength !== listTerminUangMuka.length) {
      isRefreshTerminNeeded = true
    }

    // validate termin angsuran length
    const terminAngsuranLength = parseInt(this.data.termin_angsuran || 0)
    if (terminAngsuranLength !== listTerminAngsuran.length) {
      isRefreshTerminNeeded = true
    }

    return isRefreshTerminNeeded
  }
}
