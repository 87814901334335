export default {
  methods: {
    getAngsuranColor (itemAngsuran) {
      if (itemAngsuran.group === 'BIAYA UNIT') {
        return 'danger'
      } else if (itemAngsuran.group === 'BIAYA TAMBAHAN') {
        return 'danger'
      } else if (itemAngsuran.group === 'UANG MUKA') {
        return 'green'
      } else if (itemAngsuran.group === 'ANGSURAN') {
        return 'primary'
      } else {
        return ''
      }
    }
  }
}
