<template lang="html">
  <div :class="[{
        'input-icon-validate-success': success,
        'input-icon-validate-danger': danger,
        'input-icon-validate-warning': warning
      }]">
    <vx-input-group>
      <vs-input v-if="isPercentage" :value="percent || 0" type="number" @input="onChangePercent" v-bind="$attrs"/>
      <v-money v-else :value="amount || 0" @input="onChangeAmount" v-bind="$attrs"/>
      <template slot="append">
        <div class="append-text btn-addon">
          <vs-button :color="buttonColor" @click="toggle" :disabled="isDisabled" :readonly="isReadonly" :class="{'hover:shadow-none cursor-default': isReadonly}">
            {{ isPercentage ? '%' : 'IDR' }}
          </vs-button>
        </div>
      </template>
    </vx-input-group>
    <label v-if="isPercentage" class="ml-1 text-xs text-primary">({{ ((total || 0) * ((percent || 0) / 100)) | idr }} IDR)</label>
    <label v-if="!isPercentage" class="ml-1 text-xs text-primary">({{ (((amount || 0) / (total || 0)) * 100) | idr(3) }} %)</label>
    <div
      v-if="success"
      class="con-text-validation span-text-validation-success vs-input--text-validation-span">
      <span class="span-text-validation">{{ successText }}</span>
    </div>
    <div
      v-else-if="danger"
      class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
      <span class="span-text-validation">{{ dangerText }}</span>
    </div>
    <div
      v-else-if="warning"
      class="con-text-validation span-text-validation-warning vs-input--text-validation-span">
      <span class="span-text-validation">{{ warningText }}</span>
    </div>
    <div
      v-if="descriptionText"
      class="con-text-validation span-text-validation vs-input--text-validation-span">
      <span class="span-text-validation">{{ descriptionText }}</span>
    </div>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'VInputPercentage',
  components: {
    VMoney
  },
  props: {
    total: {
      required: true,
      type: Number,
      default: 0
    },
    percentage: {
      required: true
    },
    amountage: {
      required: true
    },
    success: {
      default: false,
      type: Boolean
    },
    danger: {
      default: false,
      type: Boolean
    },
    warning: {
      default: false,
      type: Boolean
    },
    successText: {
      default: null,
      type: String
    },
    dangerText: {
      default: null,
      type: String
    },
    warningText: {
      default: null,
      type: String
    },
    descriptionText: {
      default: null,
      type: String
    },
    isDefaultPercentage: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDisabled () {
      return (('disabled' in this.$attrs && this.$attrs.disabled !== false) || this.$attrs.disabled === true)
    },
    isReadonly () {
      return (('readonly' in this.$attrs && this.$attrs.readonly !== false) || this.$attrs.readonly === true)
    },
    buttonColor () {
      if (this.success) return 'success'
      if (this.danger) return 'danger'
      if (this.warning) return 'warning'
      return 'primary'
    }
  },
  watch: {
    total () {
      if (this.isPercentage) {
        this.percent = this.percentage || 0
        this.onChangePercent(this.percent)
      } else {
        this.amount = this.amountage || 0
        this.onChangeAmount(this.amount)
      }
      this.emitChanges()
    },
    percentage (percentage) {
      this.percent = percentage || 0
    },
    amountage (amountage) {
      this.amount = amountage || 0
    }
  },
  data () {
    return {
      isPercentage: this.isDefaultPercentage,
      percent: 0,
      amount: 0
    }
  },
  methods: {
    onChangePercent (percent) {
      this.percent = percent
      this.amount = this.total * (percent / 100)
      this.emitChanges()
    },

    onChangeAmount (amount) {
      this.percent = (amount / this.total) * 100
      this.amount = amount
      this.emitChanges()
    },

    toggle () {
      if (!this.isReadonly) {
        this.isPercentage = !this.isPercentage
        this.percent = 0
        this.amount = 0
        this.emitChanges()
      }
    },

    emitChanges () {
      this.$emit('update:percentage', parseFloat(this.percent) || 0)
      this.$emit('update:amountage', parseFloat(this.amount) || 0)
    }
  }
}
</script>
