export const listTipeBiayaUnit = [
  {
    type: 'BIAYA BPHTB',
    label: 'Biaya BPHTB',
    description: 'Biaya BPHTB',
    is_as_termin: false
  },
  {
    type: 'BIAYA ADMINISTRASI',
    label: 'Biaya Administrasi',
    description: 'Biaya Administrasi',
    is_as_termin: false
  },
  {
    type: 'BIAYA TINGGI',
    label: 'Biaya Kelebihan Tinggi',
    description: 'Biaya Kelebihan Tinggi',
    is_as_termin: false
  },
  {
    type: 'BIAYA LUAS',
    label: 'Biaya Kelebihan Luas',
    description: 'Biaya Kelebihan Luas',
    is_as_termin: false
  },
  {
    type: 'BIAYA VIEW',
    label: 'Biaya View',
    description: 'Biaya View',
    is_as_termin: false
  },
  {
    type: 'BIAYA NOTARIS',
    label: 'Biaya Notaris',
    description: 'Biaya Notaris',
    is_as_termin: false
  },
  {
    type: 'BIAYA AJB',
    label: 'Biaya AJB',
    description: 'Biaya AJB',
    is_as_termin: false
  },
  {
    type: 'OTHER',
    label: 'Lainnya',
    description: null,
    is_as_termin: false
  }
]

export const listTipeBiayaTambahan = [
  {
    type: 'BOOKING FEE',
    label: 'Booking Fee',
    description: 'Booking Fee',
    is_as_termin: true
  },
  {
    type: 'OTHER',
    label: 'Lainnya',
    description: null,
    is_as_termin: false
  }
]
