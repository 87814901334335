import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import BankKprRepository from '@/repositories/master/bank-kpr-repository'
import SystemParameterRepository from '@/repositories/general/system-parameter-repository'
import formValidation from '@/views/pages/marketing/booking/mixins/tab-angsuran/formValidation'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { listTipeBiayaTambahan, listTipeBiayaUnit } from '@/views/pages/marketing/booking/constants/booking.contant'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  mounted () {
    this.getAllCaraBayar()
    this.getAllBankKpr()
    this.getAllJenisPph()
  },
  watch: {
    totalHargaJual () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.pph_jml' () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.id_cara_bayar' (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.data.id_properti_unit) {
          this.getHargaUnit()
        }
      }
    },
    'data.id_properti_unit' (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.data.id_cara_bayar) {
          this.getHargaUnit()
        }
      }
    }
  },
  computed: {
    ...formValidation,
    totalBiayaUnit () {
      return _.sumBy(this.data.listBiayaUnit, item => item.amount || 0)
    },
    totalHargaUnit () {
      return (this.data.harga_standar - this.data.diskon_jml) + this.totalBiayaUnit
    },
    dpp () {
      return this.totalHargaUnit
    },
    totalBiayaTambahan () {
      return _.sumBy(this.data.listBiayaTambahan, item => item.amount || 0)
    },
    totalHargaJual () {
      return this.dpp + this.data.ppn_jml + this.totalBiayaTambahan
    },
    totalBiayaUnitAsTermin () {
      return _.sumBy(this.listBiayaUnitAsTermin, item => item.amount || 0)
    },
    totalBiayaTambahanAsTermin () {
      return _.sumBy(this.listBiayaTambahanAsTermin, item => item.amount || 0)
    },
    totalUangMuka () {
      return this.data.dp_jml || 0
    },
    totalSisaAngsuran () {
      return this.totalHargaJual - this.totalBiayaUnitAsTermin - this.totalBiayaTambahanAsTermin - this.totalUangMuka
    },
    isTerminTotalNominalNotBalance () {
      return this.footerTermin.totalNominal.toFixed(2).toString() !== this.totalHargaJual.toFixed(2).toString()
    },
    isCanGenerateTermins () {
      return this.data.angsurans.length < 1 && this.data.termin_angsuran && this.data.angsuran_start_due
    },
    listBiayaUnitAsTermin () {
      return _.filter(this.data.listBiayaUnit, item => !!item.is_as_termin)
    },
    listBiayaTambahanAsTermin () {
      return _.filter(this.data.listBiayaTambahan, item => !!item.is_as_termin)
    },
    footerTermin () {
      return {
        totalPercent: _.sumBy(this.data.angsurans, item => item.persen),
        totalDpp: _.sumBy(this.data.angsurans, item => item.dpp),
        totalNominal: _.sumBy(this.data.angsurans, item => item.jml_nominal),
        totalPpn: _.sumBy(this.data.angsurans, item => item.jml_ppn),
        totalPph: _.sumBy(this.data.angsurans, item => item.jml_pph)
      }
    }
  },
  data () {
    return {
      data: {
        id_properti_unit: null,
        id_coa_debet: 137,
        nama_coa_debet: 'Piutang Customer',
        id_cara_bayar: null,
        id_bank_kpr: null,
        harga_standar: 0,
        diskon_persen: 0,
        diskon_jml: 0,
        ppn_persen: 0,
        ppn_jml: 0,
        pph_persen: 0,
        pph_jml: 0,
        pph_key: null,
        pph_nama: null,
        termin_dp: 0,
        dp_persen: 0,
        dp_jml: 0,
        termin_angsuran: null,
        angsuran_start_due: null,
        angsurans: [],
        listBiayaUnit: [],
        listBiayaTambahan: [],
        angsuranDeletedIds: [],
        listBiayaDeletedIds: []
      },
      defaultHargaUnit: {
        hpp_tanah: 0,
        hpp_bangunan: 0,
        harga_standar: 0,
        harga_min: 0,
        harga_max: 0
      },
      modalCoa: {
        active: false
      },
      caraBayars: [],
      bankKprs: [],
      listPphJenis: [],
      listTipeBiayaUnit: listTipeBiayaUnit,
      listTipeBiayaTambahan: listTipeBiayaTambahan
    }
  },
  methods: {
    onSelectedModalCoa (item) {
      this.data.id_coa_debet = item.id
      this.data.nama_coa_debet = item.nama
      this.modalCoa.active = false
    },

    getHargaUnit () {
      const params = {
        id_properti_unit: this.data.id_properti_unit,
        id_cara_bayar: this.data.id_cara_bayar
      }
      PropertiUnitRepository.getHarga(params)
        .then(response => {
          const data = response.data.data
          this.defaultHargaUnit = {
            hpp_tanah: data.hpp_tanah,
            hpp_bangunan: data.hpp_bangunan,
            harga_standar: data.harga_standar,
            harga_min: data.harga_min,
            harga_max: data.harga_max
          }

          if (!this.initData && !this.data.id_booking) {
            this.data.harga_standar = data.harga_standar
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    onTypeBiayaUnitChanged (row) {
      const type = _.find(listTipeBiayaUnit, item => item.type === row.type)
      row.description = type.description
      row.is_as_termin = type.is_as_termin
    },

    onTypeBiayaTambahanChanged (row) {
      const type = _.find(listTipeBiayaTambahan, item => item.type === row.type)
      row.description = type.description
      row.is_as_termin = type.is_as_termin
    },

    addRowBiayaUnit () {
      this.data.listBiayaUnit.push({
        uuid: uuid(),
        id: null,
        group: 'BIAYA UNIT',
        type: null,
        description: null,
        amount: 0,
        is_as_termin: false
      })
    },

    addRowBiayaTambahan () {
      this.data.listBiayaTambahan.push({
        uuid: uuid(),
        id: null,
        group: 'BIAYA TAMBAHAN',
        type: null,
        description: null,
        amount: 0,
        is_as_termin: false
      })
    },

    removeRowBiayaUnit (uuid) {
      const index = _.findIndex(this.data.listBiayaUnit, item => item.uuid === uuid)
      const item = this.data.listBiayaUnit[index]
      if (item.id) {
        this.data.listBiayaDeletedIds.push(item.id)
      }
      this.data.listBiayaUnit.splice(index, 1)
    },

    removeRowBiayaTambahan (uuid) {
      const index = _.findIndex(this.data.listBiayaTambahan, item => item.uuid === uuid)
      const item = this.data.listBiayaTambahan[index]
      if (item.id) {
        this.data.listBiayaDeletedIds.push(item.id)
      }
      this.data.listBiayaTambahan.splice(index, 1)
    },

    clearTermins () {
      this.data.angsurans.forEach(item => {
        if (item.id) {
          this.data.angsuranDeletedIds.push(item.id)
        }
      })
      this.data.angsurans = []
    },

    refreshTermins () {
      this.clearTermins()
      this.generateTermins()
    },

    generateTermins () {
      // generate termin
      const angsurans = [
        ...this.generateTerminBiayaTambahan(),
        ...this.generateTerminBiayaUnit(),
        ...this.generateTerminUangMuka(),
        ...this.generateTerminAngsuran()
      ].map((item, index) => {
        item.no_termin = index
        return item
      })

      // cek selisih pembulatan
      const grandTotalTerminGenerated = parseFloat((_.sumBy(angsurans, item => parseFloat(item.jml_nominal.toFixed(2)))).toFixed(2))
      const totalHargaJual = parseFloat(this.totalHargaJual.toFixed(2))
      if (grandTotalTerminGenerated !== totalHargaJual) {
        const lastIndex = angsurans.length - 1
        angsurans[lastIndex].jml_nominal += (totalHargaJual - grandTotalTerminGenerated)
      }

      // set termin
      this.data.angsurans = angsurans
    },

    generateTerminBiayaUnit () {
      return this.listBiayaUnitAsTermin.map((item) => {
        const tipe = item.type === 'OTHER' ? (item.description || '').toUpperCase() : item.type
        const nominal = item.amount
        const persen = (nominal / this.totalHargaJual) * 100
        const dpp = this.dpp * (persen / 100)
        const ppn = this.data.ppn_jml * (persen / 100)
        const pph = this.data.pph_jml * (persen / 100)

        return {
          group: 'BIAYA UNIT',
          tipe_angsuran: tipe,
          no_termin: null,
          tgl_due: null,
          persen: persen,
          jml_nominal: nominal,
          dpp: dpp,
          jml_ppn: ppn,
          jml_pph: pph,
          keterangan: null
        }
      })
    },

    generateTerminBiayaTambahan () {
      return this.listBiayaTambahanAsTermin.map((item) => {
        const tipe = item.type === 'OTHER' ? (item.description || '').toUpperCase() : item.type
        const nominal = item.amount
        const persen = (nominal / this.totalHargaJual) * 100
        const dpp = this.dpp * (persen / 100)
        const ppn = this.data.ppn_jml * (persen / 100)
        const pph = this.data.pph_jml * (persen / 100)

        return {
          group: 'BIAYA TAMBAHAN',
          tipe_angsuran: tipe,
          no_termin: null,
          tgl_due: null,
          persen: persen,
          jml_nominal: nominal,
          dpp: dpp,
          jml_ppn: ppn,
          jml_pph: pph,
          keterangan: null
        }
      })
    },

    generateTerminUangMuka () {
      const terminCount = this.data.termin_dp
      const total = this.totalUangMuka
      const nominal = (total / terminCount)
      const persen = (nominal / this.totalHargaJual) * 100
      const dpp = this.dpp * (persen / 100)
      const ppn = this.data.ppn_jml * (persen / 100)
      const pph = this.data.pph_jml * (persen / 100)

      const termins = []
      for (let i = 0; i < terminCount; i++) {
        termins.push({
          group: 'UANG MUKA',
          tipe_angsuran: 'UANG MUKA',
          no_termin: null,
          tgl_due: null,
          persen: persen,
          jml_nominal: nominal,
          dpp: dpp,
          jml_ppn: ppn,
          jml_pph: pph,
          keterangan: null
        })
      }

      return termins
    },

    generateTerminAngsuran () {
      const terminCount = this.data.termin_angsuran
      const dueDate = this.data.angsuran_start_due
      const total = this.totalSisaAngsuran
      const nominal = (total / terminCount)
      const persen = (nominal / this.totalHargaJual) * 100
      const dpp = this.dpp * (persen / 100)
      const ppn = this.data.ppn_jml * (persen / 100)
      const pph = this.data.pph_jml * (persen / 100)

      const termins = []
      for (let i = 0; i < terminCount; i++) {
        termins.push({
          group: 'ANGSURAN',
          tipe_angsuran: 'ANGSURAN',
          no_termin: null,
          tgl_due: dueDate ? moment(dueDate).add(i, 'months').format('YYYY-MM-DD') : null,
          persen: persen,
          jml_nominal: nominal,
          dpp: dpp,
          jml_ppn: ppn,
          jml_pph: pph,
          keterangan: null
        })
      }

      return termins
    },

    updateAllTerminItemCalculation: _.debounce(function (value) {
      this.data.angsurans.forEach((item, index) => {
        this.updateTerminItemCalculation(index)
      })
    }, 1000),

    updateTerminItemCalculation (index) {
      const newNominal = this.data.angsurans[index].jml_nominal
      const newPersen = (newNominal / this.totalHargaJual) * 100

      this.data.angsurans[index].persen = newPersen
      this.data.angsurans[index].dpp = this.dpp * (newPersen / 100)
      this.data.angsurans[index].jml_ppn = this.data.ppn_jml * (newPersen / 100)
      this.data.angsurans[index].jml_pph = this.data.pph_jml * (newPersen / 100)
    },

    adjustNominalForLastTermin () {
      if (this.data.angsurans.length > 0) {
        const lastIndex = this.data.angsurans.length - 1
        const balance = this.totalHargaJual - this.footerTermin.totalNominal
        this.data.angsurans[lastIndex].jml_nominal += balance
      }
    },

    getAllJenisPph () {
      const params = { group: 'PPH' }
      SystemParameterRepository.getAll(params)
        .then(response => {
          this.listPphJenis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChangePphJenis (pphKey) {
      const data = _.find(this.listPphJenis, item => item.key === pphKey)
      this.data.pph_persen = data.value
      this.data.pph_jml = this.dpp * (data.value / 100)
      this.data.pph_nama = data.alias
      this.data.pph_key = data.key
    },

    getAllCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'KONTRAK' })

      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllBankKpr () {
      BankKprRepository.getAll()
        .then(response => {
          this.bankKprs = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }
}
